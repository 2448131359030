import dayjs from "dayjs";
import { styled, Box } from "@mui/material";
import { RangeTemperatureIcon } from "src/assets/icons";
import {
  Colors,
  DEFAULT_DEVICE_ID,
  MINUTE,
  RangeScaleAlignment,
  UNIT_TYPE_DAY,
} from "src/system/constants";
import ProgressBar from "../../../components/ProgressBar";
import {
  useGetDataEcoflowQuery,
} from "../../../state/reducers/notificationsApi";
import { Datatype } from "src/types/apiTypes";

const TEMPERATURE_LOWER_BOUNDARY_LABEL = "0";
const TEMPERATURE_UPPER_BOUNDARY_LABEL = "100";

const RangeEnergy = () => {
  const { data: GetDataEcoflowShowSoc } = useGetDataEcoflowQuery({
    device_id: DEFAULT_DEVICE_ID,
    datatype: Datatype.BatteryManagementSystem,
    lower_bound: dayjs().startOf(UNIT_TYPE_DAY).unix(),
    upper_bound: dayjs().startOf(MINUTE).unix(),
  });

  const lastTimestamp = (
    GetDataEcoflowShowSoc?.[GetDataEcoflowShowSoc?.length - 1]?.temp ?? 0
  ).toFixed(2);

  const latestDataNumber = parseFloat(lastTimestamp);

  return (
    <RangeEnergyBlock>
      <RangeTemperatureSection>
        <RangeTemperatureIcon />
      </RangeTemperatureSection>
      <Box sx={{ height: 190 }} className="boxSlider">
        <span className="paramSlider">{TEMPERATURE_UPPER_BOUNDARY_LABEL}</span>
        <ProgressBar
          value={latestDataNumber}
          alignment={RangeScaleAlignment.RIGHT}
          units="°C"
        />
        <span className="paramSlider">{TEMPERATURE_LOWER_BOUNDARY_LABEL}</span>
      </Box>
    </RangeEnergyBlock>
  );
};

const RangeEnergyBlock = styled(Box)({
  padding: "0 0 0 0",
  margin: "-9px 0 0 0",
  position: "relative",
  height: "190px",
  width: "40px",
  "& .boxSlider .MuiSlider-thumb": {
    zIndex: "100",
  },
  "& .boxSlider": {
    display: "flex",
    justifyContent: "space-between",
  },
  "& .boxSlider .sliderTemperature .MuiSlider-valueLabelLabel:before": {
    content: "'°'",
    position: "absolute",
    top: "0",
    right: "-10px",
    width: "10px",
    height: "10px",
  },
  ".boxSlider": {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    position: "relative",
    pointerEvents: "none",
  },
  ".boxSlider::before": { content: '""', position: "absolute" },
  ".boxSlider .paramSlider": {
    fontSize: "16px",
    lineHeight: "19px",
    marginBottom: "11px",
  },
  ".boxSlider .paramSlider:last-of-type": {
    marginBottom: "0",
    marginTop: "6px",
  },
  ".boxSlider .sliderTemperature": {
    width: "2px",
    color: Colors.kinglyCloud,
  },
  ".boxSlider .MuiSlider-track": {
    backgroundColor: `${Colors.kinglyCloud}!important`,
  },
  ".boxSlider .MuiSlider-thumb": { width: "14px", height: "14px" },
  ".boxSlider .MuiSlider-valueLabelOpen::before": { display: "none" },
  ".boxSlider .sliderTemperature .MuiSlider-thumb": {
    backgroundColor: Colors.darkLightGreen,
  },
  ".boxSlider .MuiSlider-thumb:hover": { boxShadow: "none" },
  ".boxSlider .sliderTemperature .MuiSlider-valueLabel": {
    backgroundColor: Colors.white,
    padding: "0",
    color: Colors.dimGray,
    right: "-25px",
    top: "19px",
  },
});

const RangeTemperatureSection = styled(Box)({
  position: "absolute",
  top: "50%",
  left: "-15px",
  margin: "5px 0 0 0",
  transform: "translate(0, -50%)",
});

export default RangeEnergy;
