import React from "react";
import ScheduleCalendar from "../../../components/ScheduleCalendar";
import CalendarData from "./CalendarData";
import { TabPanelUnstyled, TabsUnstyled } from "@mui/base";
import { Box, styled, Typography } from "@mui/material";
import {
  Colors,
  ParamTooltipTypes,
  DevicesIds,
  DeviceTypes,
  UnitTypes,
  DataType,
  UnitTypesIntervalStr,
  DEFAULT_DOMAIN_START_VALUE,
  DEFAULT_TICK_COUNT_VALUE,
  DEFAULT_DOMAIN_END_VALUE_RADON,
} from "src/system/constants";
import CalendarTablist from "./CalendarTablist";
import ParametrsDetailsCalendar from "./ParametrsDetailsCalendar";
import {CalendarMode} from "../../../components/DateRangePicker";
import { CalendarProps } from "src/types/dashboardTypes";

const CalendarRadon: React.FC<CalendarProps> = ({ date, setDate }) => {

  return (
    <CalendarStyled className="humidityCalendar">
      <ParametrsDetailsCalendar
        renderSingleValue
        titleDetailsCalendar="Radon"
        firstClassNameDetailsCalendar="radon"
        firstNameDetailsCalendar="Bq/m³"
      />
      <TabsLine>
        <TabsUnstyled defaultValue={2}>
          <CalendarTablist />
          <TabPanelUnstyled value={0}>
            <CalendarData
              date={date}
              setDate={setDate}
              mode={CalendarMode.DAY}
            />
            <Typography className="ScheduleDay-indent">
              <ScheduleCalendar
                date={date}
                dataType={DataType.SENSORS}
                deviceId={DevicesIds.WAVE_PLUS_ID}
                deviceType={DeviceTypes.WAVE_PLUS}
                renderSingleValue
                renderGradient
                renderArea
                classNameTooltipFirst="radon"
                paramTooltipFirst={ParamTooltipTypes.RADON_ST_AVG}
                paramNameTooltipFirst="Bq/m³"
                stopOpacityGradientFirst="0.3"
                stopOpacityGradientSecond="0.03"
                stopColorGradientFirst={Colors.skylLightBlue}
                domainStart={DEFAULT_DOMAIN_START_VALUE}
                domainEnd={DEFAULT_DOMAIN_END_VALUE_RADON}
                tickCount={DEFAULT_TICK_COUNT_VALUE}
                kind={UnitTypes.DAY}
                unitType={UnitTypes.DAY}
                intervalStr={UnitTypesIntervalStr.DAY}
              />
            </Typography>
          </TabPanelUnstyled>
          <TabPanelUnstyled value={1}>
            <CalendarData
              date={date}
              setDate={setDate}
              mode={CalendarMode.WEEK}
            />
            <Typography className="ScheduleDay-indent">
              <ScheduleCalendar
                date={date}
                dataType={DataType.SENSORS}
                deviceId={DevicesIds.WAVE_PLUS_ID}
                deviceType={DeviceTypes.WAVE_PLUS}
                renderSingleValue
                renderGradient
                renderArea
                classNameTooltipFirst="radon"
                paramTooltipFirst={ParamTooltipTypes.RADON_ST_AVG}
                paramNameTooltipFirst="Bq/m³"
                stopOpacityGradientFirst="0.3"
                stopOpacityGradientSecond="0.03"
                stopColorGradientFirst={Colors.skylLightBlue}
                domainStart={DEFAULT_DOMAIN_START_VALUE}
                domainEnd={DEFAULT_DOMAIN_END_VALUE_RADON}
                tickCount={DEFAULT_TICK_COUNT_VALUE}
                kind={UnitTypes.WEEK}
                unitType={UnitTypes.WEEK}
                intervalStr={UnitTypesIntervalStr.WEEK}
              />
            </Typography>
          </TabPanelUnstyled>
          <TabPanelUnstyled value={2}>
            <CalendarData
              date={date}
              setDate={setDate}
              mode={CalendarMode.MONTH}
            />
            <Typography className="ScheduleDay-indent">
              <ScheduleCalendar
                date={date}
                dataType={DataType.SENSORS}
                deviceId={DevicesIds.WAVE_PLUS_ID}
                deviceType={DeviceTypes.WAVE_PLUS}
                renderSingleValue
                renderGradient
                renderArea
                classNameTooltipFirst="radon"
                paramTooltipFirst={ParamTooltipTypes.RADON_ST_AVG}
                paramNameTooltipFirst="Bq/m³"
                stopOpacityGradientFirst="0.3"
                stopOpacityGradientSecond="0.03"
                stopColorGradientFirst={Colors.skylLightBlue}
                domainStart={DEFAULT_DOMAIN_START_VALUE}
                domainEnd={DEFAULT_DOMAIN_END_VALUE_RADON}
                tickCount={DEFAULT_TICK_COUNT_VALUE}
                kind={UnitTypes.MONTH}
                unitType={UnitTypes.MONTH}
                intervalStr={UnitTypesIntervalStr.MONTH}
              />
            </Typography>
          </TabPanelUnstyled>
          <TabPanelUnstyled value={3}>
            <CalendarData
                date={date}
                setDate={setDate}
                mode={CalendarMode.YEAR}
            />
            <Typography className="ScheduleDay-indent">
              <ScheduleCalendar
                date={date}
                dataType={DataType.SENSORS}
                deviceId={DevicesIds.WAVE_PLUS_ID}
                deviceType={DeviceTypes.WAVE_PLUS}
                renderSingleValue
                renderGradient
                renderArea
                classNameTooltipFirst="radon"
                paramTooltipFirst={ParamTooltipTypes.RADON_ST_AVG}
                paramNameTooltipFirst="Bq/m³"
                stopOpacityGradientFirst="0.3"
                stopOpacityGradientSecond="0.03"
                stopColorGradientFirst={Colors.skylLightBlue}
                domainStart={DEFAULT_DOMAIN_START_VALUE}
                domainEnd={DEFAULT_DOMAIN_END_VALUE_RADON}
                tickCount={DEFAULT_TICK_COUNT_VALUE}
                kind={UnitTypes.YEAR}
                unitType={UnitTypes.YEAR}
                intervalStr={UnitTypesIntervalStr.YEAR}
              />
            </Typography>
          </TabPanelUnstyled>
        </TabsUnstyled>
      </TabsLine>
    </CalendarStyled>
  );
};

const CalendarStyled = styled(Box)({
  boxShadow: `0 0 3px ${Colors.lightGray}`,
  width: "100%",
  marginTop: "15px",
  padding: "25px 0 0 0",
  h2: { paddingLeft: "25px" },
  ".wrap__title": {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "flex-start",
  },
  ".wrap__colors": {
    marginRight: "15px",
    fontWeight: 400,
    fontSize: "14px",
    lineHeight: "16px",
    color: Colors.lightBlack,
    display: "flex",
  },
  ".param__datails": { display: "flex", alignItems: "center" },
  ".param__datails:first-of-type": { marginRight: "24px" },
  ".param__datails:last-of-type": { marginRight: "0" },
  ".wrap__colors .range": {
    position: "relative",
    width: "31px",
    height: "2px",
    backgroundColor: Colors.peachCobbler,
    marginRight: "5px",
  },
  ".wrap__colors .range.temp": { backgroundColor: Colors.brightGreen },
  ".wrap__colors .range.production": { backgroundColor: Colors.skyLightBlue },
  ".wrap__colors .range.humudity": { backgroundColor: Colors.heavenly },
  ".wrap__colors .range.press": { backgroundColor: Colors.bicycleYellow },
  ".wrap__colors .range.radon": { backgroundColor: Colors.heavenly },
  ".wrap__colors .range::after": {
    content: '""',
    position: "absolute",
    width: "10px",
    height: "10px",
    borderRadius: "50%",
    backgroundColor: Colors.peachCobbler,
    right: "0",
    top: "50%",
    transform: "translateY(-50%)",
  },
  ".wrap__colors .range.temp::after": { backgroundColor: Colors.brightGreen },
  ".wrap__colors .range.production::after": {
    backgroundColor: Colors.skyLightBlue,
  },
  ".wrap__colors .range.humudity::after": { backgroundColor: Colors.heavenly },
  ".wrap__colors .range.press::after": {
    backgroundColor: Colors.bicycleYellow,
  },
  ".wrap__colors .range.radon::after": { backgroundColor: Colors.heavenly },
  ".month_bottom": {
    display: "flex",
    justifyContent: "space-between",
    width: "100%",
    margin: "-20px 0 0 0",
    padding: "0 0 0 30px",
  },
  ".month_bottom-text": {
    fontSize: "15px",
    lineHeight: "17px",
    color: Colors.black,
  },
});

const TabsLine = styled(Box)({
  padding: "0",
  "& .ScheduleDay-indent": {
    position: "relative",
    padding: "0 18px 21px 10px",
  },
});

export default CalendarRadon;
