import { Box, styled, Typography } from "@mui/material";
import {
  Colors,
  DECIMAL_PLACES,
  DEFAULT_DATE_FORMAT,
  DEFAULT_DEVICE_ID,
  MINUTE,
  TypeLabels,
  UNIT_TYPE_DAY,
} from "src/system/constants";
import { DataTypeKey } from "src/types/connectedStationType";
import { getLastExistingValueInArrayofObjects } from "../../../utils/getLastExistingValueInArrayofObjects";
import { MyObject } from "../../../types/appTypes";
import {
  useGetDataEcoflowQuery,
  useGetProducedConsumedEnergyQuery,
} from "../../../state/reducers/notificationsApi";
import dayjs from "dayjs";
import { Datatype } from "src/types/apiTypes";

type BatteryInfoProps = {
  type: DataTypeKey;
  maximum?: boolean;
  inWattsProps?: boolean;
  inVoltProps?: boolean;
};

const BatteryInfo: React.FC<BatteryInfoProps> = ({
  type,
  maximum,
  inWattsProps,
  inVoltProps,
}) => {
  const { data: ProducedConsumedEnergy } = useGetProducedConsumedEnergyQuery({
    device_id: DEFAULT_DEVICE_ID,
    date: dayjs().format(DEFAULT_DATE_FORMAT)
  });
  const { data } = useGetDataEcoflowQuery({
    device_id: DEFAULT_DEVICE_ID,
    datatype: maximum
      ? Datatype.MaximumPowerPointTracking
      : Datatype.BatteryManagementSystem,
    lower_bound: dayjs().startOf(UNIT_TYPE_DAY).unix(),
    upper_bound: dayjs().startOf(MINUTE).unix(),
  });

  const producedPower = Number(
    (ProducedConsumedEnergy?.produced_power ?? 0).toFixed(2)
  );

  const consumedPower = Number(
    (ProducedConsumedEnergy?.consumed_power ?? 0).toFixed(2)
  );

  const latestData = (
    getLastExistingValueInArrayofObjects(data as unknown as MyObject[], type) ??
    0
  ).toFixed(DECIMAL_PLACES);

  const latestDataNumber = parseFloat(latestData);

  return (
    <BatteryInfoBlock>
      <h2>{TypeLabels[type].header}</h2>
      <BatteryInfoBlockValue>
        <span>
          {inWattsProps
            ? producedPower
            : inVoltProps
            ? consumedPower
            : latestDataNumber}

          <Typography>{TypeLabels[type].unit}</Typography>
        </span>
      </BatteryInfoBlockValue>
    </BatteryInfoBlock>
  );
};

const BatteryInfoBlock = styled(Box)(({ theme }) => ({
  boxShadow: `0 0 3px ${Colors.lightGray}`,
  padding: "18px 19px 26px 15px",
  width: "31.7%",
  marginBottom: "20px",
  "& h2": {
    fontSize: "16px !important",
    lineHeight: "18px !important",
    color: `${Colors.lightBlack} !important`,
    fontWeight: "500",
    padding: "0",
  },
  [theme.breakpoints.down("xl")]: {
    width: "32%",
    padding: "18px 12px 26px 12px",
    "& h2": {
      fontSize: "13px !important",
      lineHeight: "16px !important",
      padding: "0",
    },
  },
  [theme.breakpoints.down("lg")]: {
    width: "32.5%",
    padding: "18px 15px 26px 15px",
    "& h2": {
      fontSize: "12px !important",
      lineHeight: "15px !important",
      padding: "0",
    },
  },
  [theme.breakpoints.down("md")]: {
    width: "100%",
  },
}));

const BatteryInfoBlockValue = styled(Box)(({ theme }) => ({
  fontSize: "42px",
  lineHeight: "49.22px",
  textAlign: "center",
  padding: "28px 0 0 0",
  fontWeight: "500",
  "& span": {
    color: Colors.lightBlack,
    display: "flex",
    justifyContent: "center",
    alignItems: "flex-end",
  },
  "& p": {
    fontWeight: "500",
    color: Colors.gray,
    fontSize: "inherit",
    lineHeight: "inherit",
    marginLeft: "10px",
  },
  [theme.breakpoints.down("xl")]: {
    fontSize: "30px",
  },
}));

export default BatteryInfo;
