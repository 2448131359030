import React, { useEffect, useState } from "react";
import RangeEnergy from "./RangeEnergy";
import RangeTemperature from "./RangeTemperature";
import dayjs from "dayjs";
import { Box, styled } from "@mui/material";
import {
  Colors,
  DEFAULT_DEVICE_ID,
  DEFAULT_DOMAIN_END_VALUE,
  MINUTE,
  PropertyEcoFlowCharger,
  TIMESTAMP_ONE_MINUTE,
  UNIT_TYPE_DAY,
} from "src/system/constants";
import { getLastExistingValueInArrayofObjects } from "../../../utils/getLastExistingValueInArrayofObjects";
import { MyObject } from "../../../types/appTypes";
import {
  useGetDataEcoflowQuery,
} from "../../../state/reducers/notificationsApi";
import OpenBattery from "./OpenBattery";
import { Datatype } from "src/types/apiTypes";

const EcoFlowCharger = () => {
  const { data } = useGetDataEcoflowQuery({
    device_id: DEFAULT_DEVICE_ID,
    datatype: Datatype.EnergyManagementSystem,
    lower_bound: dayjs().startOf(UNIT_TYPE_DAY).unix(),
    upper_bound: dayjs().startOf(MINUTE).unix(),
  });

  let lastTimestamp;
  let chg_state =
    getLastExistingValueInArrayofObjects(
      data as unknown as MyObject[],
      PropertyEcoFlowCharger.CHG_STATE
    ) ?? 0;

  lastTimestamp =
    chg_state === 0 || chg_state === 1
      ? getLastExistingValueInArrayofObjects(
          data as unknown as MyObject[],
          PropertyEcoFlowCharger.REMAIN_TIME
        ) ?? 0
      : chg_state === 2
      ? getLastExistingValueInArrayofObjects(
          data as unknown as MyObject[],
          PropertyEcoFlowCharger.DISCHARGE_TIME
        ) ?? 0
      : 0;

  lastTimestamp = Math.floor(lastTimestamp / DEFAULT_DOMAIN_END_VALUE);
  const lastTimestampEnergy = getLastExistingValueInArrayofObjects(
    data as unknown as MyObject[],
    PropertyEcoFlowCharger.LCD_SHOW_SOC
  );

  const [updateKey, setUpdateKey] = useState(0);
  useEffect(() => {
    const interval = setInterval(
      () => setUpdateKey((x) => x + 1),
      TIMESTAMP_ONE_MINUTE
    );
    return () => clearInterval(interval);
  }, []);

  return (
    <EcoFlowChargerBox>
      <h2>
        EcoFlow <span>Charger</span>
      </h2>
      <WrapBatteryParametrs>
        <RangeTemperatureBlock>
          <RangeTemperature key={updateKey} />
        </RangeTemperatureBlock>
        <BatteryBlock key={updateKey}>
          <OpenBattery lastTimestampEnergy={lastTimestampEnergy} />
          <h3>{lastTimestamp} h</h3>
        </BatteryBlock>
        <RangeEnergyBlock>
          <RangeEnergy key={updateKey} />
        </RangeEnergyBlock>
      </WrapBatteryParametrs>
    </EcoFlowChargerBox>
  );
};

const EcoFlowChargerBox = styled(Box)(({ theme }) => ({
  boxShadow: "0px 0px 3px 1px rgba(0, 0, 0, 0.08)",
  height: "100%",
  padding: "25px 24px 24px 24px",
  "& h2": {
    fontWeight: "500",
    fontSize: "22px",
    lineHeight: "26px",
    color: Colors.gray,
    marginBottom: "79px",

    "& span": {
      color: Colors.lightBlack,
    },
  },
  [theme.breakpoints.down("xl")]: {
    "& h2": {
      fontSize: "20px",
      lineHeight: "22px",
    },
  },
  [theme.breakpoints.down("lg")]: {
    "& h2": {
      fontSize: "16px",
      lineHeight: "18px",
    },
  },
}));

const WrapBatteryParametrs = styled(Box)({
  display: "flex",
  alignItems: "flex-start",
  justifyContent: "center",
});

const RangeTemperatureBlock = styled(Box)(({ theme }) => ({
  marginRight: "49px",
  [theme.breakpoints.down("xl")]: {
    marginRight: "20px",
  },
  [theme.breakpoints.down("lg")]: {
    marginRight: "0px",
  },
}));

const BatteryBlock = styled(Box)({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  "& svg": {
    marginBottom: "8px",
  },
  "& h3": {
    color: Colors.lightBlack,
    fontWeight: "500",
    fontSize: "32px",
    lineHeight: "38px",
  },
});

const RangeEnergyBlock = styled(Box)(({ theme }) => ({
  marginLeft: "50px",
  [theme.breakpoints.down("xl")]: {
    marginLeft: "20px",
  },
  [theme.breakpoints.down("lg")]: {
    marginLeft: "0px",
  },
}));

export default EcoFlowCharger;
