import { Box, List, ListItem, styled } from "@mui/material";
import HelpOutlineOutlinedIcon from "@mui/icons-material/HelpOutlineOutlined";
import {
  Colors,
  SensorStatus,
  ZoneDataName,
  DEFAULT_DATE_FORMAT,
  NO_DATA_VALUE,
  HOUR,
  DEFAULT_DEVICE_ID,
  DEFAULT_LOWER_BOUND,
  TooltipTitleMessages,
} from "src/system/constants";
import { DeviceData } from "src/types/dashboardTypes";
import dayjs from "dayjs";
import IOTooltip from "../../../components/Tooltip";
import {
  useGetDataEcoflowQuery,
} from "../../../state/reducers/notificationsApi";
import ZoneDataValue from "../../ConnectedSensors/components/ZoneDataValue";
import { Datatype } from "src/types/apiTypes";

const ZERO_STRING = "0";
const MIN_FRACTION_DIGITS = 0;
const MAX_FRACTION_DIGITS = 0;

const ZoneData: React.FC<{
  type?: string;
  data?: DeviceData;
  value?: boolean;
}> = ({ type, data, value }) => {
  const latestData = data?.items?.[data?.items ? data?.items.length - 1 : 0];
  const pollutionDataCo2 = latestData?.co2 ?? NO_DATA_VALUE;
  const pollutionDataVoc = latestData?.voc ?? NO_DATA_VALUE;

  const humidityData = latestData?.humidity
    ? latestData.humidity
    : NO_DATA_VALUE;
  const temperatureData = latestData?.temperature
    ? Math.ceil(latestData.temperature ?? 0)
    : NO_DATA_VALUE;
  const pressureData = latestData?.pressure
    ? latestData.pressure?.toFixed()
    : NO_DATA_VALUE;
  const radonData = latestData?.radon_st_avg
    ? latestData.radon_st_avg
    : NO_DATA_VALUE;

  const baseDate = dayjs(
    dayjs().add(1, "day").format(DEFAULT_DATE_FORMAT),
    DEFAULT_DATE_FORMAT
  );

  const { data: DataEcoflowMaximum } = useGetDataEcoflowQuery({
    device_id: DEFAULT_DEVICE_ID,
    datatype: Datatype.PowerDelivery,
    lower_bound: DEFAULT_LOWER_BOUND,
    upper_bound: dayjs().startOf(HOUR).toDate().valueOf(),
  });

  const dataVoltage =
    DataEcoflowMaximum?.[DataEcoflowMaximum.length - 1]?.watts_out_sum;

  let formattedData = NO_DATA_VALUE;

  //because there should be no more than 2 numbers after the dot
  if (dataVoltage !== undefined && dataVoltage !== null) {
    formattedData =
      dataVoltage === 0
        ? ZERO_STRING
        : Number(dataVoltage).toLocaleString(undefined, {
            minimumFractionDigits: MIN_FRACTION_DIGITS,
            maximumFractionDigits: MAX_FRACTION_DIGITS,
          });
  }

  return (
    <>
      {type === ZoneDataName.POLLUTION ? (
        <PollutionZoneContainer className={value ? SensorStatus.ACTIVE : ""}>
          <List aria-label="basic-list">
            <ListItem data-testid="result-polution-co2">
              {pollutionDataCo2} ppm
              <IOTooltip title={TooltipTitleMessages.CO2_TOOLTIP_TITLE}>
                <HelpOutlineOutlinedIcon color="info" />
              </IOTooltip>
            </ListItem>
            <ListItem data-testid="result-polution-voc">
              {pollutionDataVoc} ppb
              <IOTooltip title={TooltipTitleMessages.VOC_TOOLTIP_TITLE}>
                <HelpOutlineOutlinedIcon color="info" />
              </IOTooltip>
            </ListItem>
          </List>
        </PollutionZoneContainer>
      ) : (
        <ZoneContainer className={value ? SensorStatus.ACTIVE : ""}>
          <ZoneDataValue
            type={type}
            humidityData={humidityData}
            dataVoltage={formattedData}
            temperatureData={temperatureData}
            pressureData={pressureData}
            radonData={radonData}
          />
        </ZoneContainer>
      )}
    </>
  );
};

const PollutionZoneContainer = styled(Box)(({ theme }) => ({
  width: "100%",
  display: "none",
  "&.active": {
    display: "block",
    color: Colors.silver,
  },
  "& ul": {
    listStyle: "none",
    textAlign: "left",
    width: "100%",
    padding: "0",
    marginTop: "13px !important",
    margin: "0",
    display: "flex",
    justifyContent: "center",
    flexDirection: "column",
    [theme.breakpoints.down("sm")]: {
      flexDirection: "row",
    },
  },
  "& li": {
    position: "relative",
    width: "fit-content",
    margin: "0 auto",
    fontSize: "24px",
    lineHeight: "normal",
    padding: "0",
    display: "flex",
    justifyContent: "center",
  },
  [theme.breakpoints.down("md")]: {
    "& li": {
      fontSize: "16px",
      lineHeight: "18px",
    },
  },
  span: {
    cursor: "pointer",
    margin: "-10px 0 0 0",
    svg: {
      fontSize: 16,
      marginRight: "0 !important",
      path: {
        fill: "white !important",
      },
    },
  },
}));

const ZoneContainer = styled(Box)(({ theme }) => ({
  fontSize: "24px",
  lineHeight: "28px",
  color: Colors.white,
  padding: "16px 0 0 0",
  display: "none",
  "&.active": {
    display: "block",
    color: Colors.silver,
  },
}));

export default ZoneData;
