import { Colors, SensorStatus } from "../system/constants";

export const getEnergyParameters = (
  status: boolean,
  inWatts: number,
  inVol: number,
  launchDate: string,
  producedPower: number
) => {
  return [
    {
      id: 1,
      name: "Status",
      value: status ? SensorStatus.ACTIVE : SensorStatus.INACTIVE,
      color: status ? Colors.greenMana : Colors.highlighterRed,
    },
    {
      id: 2,
      name: "Produced energy per day",
      value: producedPower + " W/h",
    },
    {
      id: 3,
      name: "Panel Efficiency",
      value: inWatts + "%",
    },
    {
      id: 4,
      name: "Launch date",
      picture: launchDate,
    },
    {
      id: 5,
      name: "Type",
      value: "DC Over Current",
    },

    {
      id: 6,
      name: "Power PV modules / Quantity",
      value: "45 W / 220 pcs",
    },
    {
      id: 7,
      name: "Power",
      value: "500 W",
    },
  ];
};
