import { createApi } from "@reduxjs/toolkit/query/react";
import { fetchBaseQuery } from "@reduxjs/toolkit/dist/query/react";
import { systemVariables } from "../../system/constants";
import {
  NotificationData,
  NotificationType,
} from "../../routes/Notifications/components/assets/types/notificationsTypes";
import { DeviceData, EcoflowData, EnergySourceConsumedData, ProducedConsumedEnergyData } from "../../types/apiTypes";

const TAG_TYPE_USERS = "notifications";
const apiPath = systemVariables.apiBaseUrl;

const baseQuery = fetchBaseQuery({ baseUrl: apiPath });

const notificationsApi = createApi({
  reducerPath: "notificationsApi",
  baseQuery,
  tagTypes: [TAG_TYPE_USERS],
  endpoints: (builder) => ({
    getNotifications: builder.query<NotificationType[], null>({
      query: () => ({
        url: "/notifications",
        method: "GET",
      }),
      providesTags: [TAG_TYPE_USERS],
    }),
    deleteNotifications: builder.mutation<
      { status: number; message: string },
      { device_id: string; timestamp: number }
    >({
      query: ({ device_id, timestamp }) => ({
        url: `/notifications?device_id=${device_id}&timestamp=${timestamp}`,
        method: "DELETE",
      }),
      invalidatesTags: [TAG_TYPE_USERS],
    }),
    updateNotifications: builder.mutation<
      null,
      { value: NotificationType; id: number }
    >({
      query: ({ id, value }) => ({
        url: `/notifications/${id}`,
        method: "PUT",
        body: value,
      }),
      invalidatesTags: [TAG_TYPE_USERS],
    }),
    getNotificationsPaginate: builder.query<NotificationData, number>({
      query: (page) => ({
        url: `/notifications/paginate?&page=${page}`,
        method: "GET",
      }),
      providesTags: [TAG_TYPE_USERS],
    }),
    updateNotificationsReadAll: builder.mutation<null, null>({
      query: () => ({
        url: `/notifications/reed-all`,
        method: "PUT",
      }),
    }),
    getDataEcoflow: builder.query<EcoflowData[], DeviceData>({
      query: ({ device_id, datatype, lower_bound, upper_bound }) => ({
        url: `data/ecoflow?device_id=${device_id}&datatype=${datatype}&lower_bound=${lower_bound}&upper_bound=${upper_bound}`,
        method: "GET",
      }),
      providesTags: [TAG_TYPE_USERS],
    }),
    getProducedConsumedEnergy: builder.query<
      ProducedConsumedEnergyData | null,
      { device_id: string,
        date: string
       }
    >({
      query: ({ device_id, date }) => ({
        url: `/data/produced-consumed-energy?device_id=${device_id}&date=${date}`,
        method: "GET",
      }),
      providesTags: [TAG_TYPE_USERS],
    }),
    getEnergySourceConsumed: builder.query<
      EnergySourceConsumedData | null,
      { device_id: string,
        date: string
       }
    >({
      query: ({ device_id, date }) => ({
        url: `/data/energy-source-consumed?device_id=${device_id}&date=${date}`,
        method: "GET",
      }),
      providesTags: [TAG_TYPE_USERS],
    }),

  }),
});

export const {
  useGetNotificationsQuery,
  useDeleteNotificationsMutation,
  useGetNotificationsPaginateQuery,
  useGetDataEcoflowQuery,
  useUpdateNotificationsReadAllMutation,
  useGetProducedConsumedEnergyQuery,
  useGetEnergySourceConsumedQuery,
} = notificationsApi;

export default notificationsApi;
