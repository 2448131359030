import React from "react";
import {
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
  AreaChart,
  Area,
} from "recharts";
import roundDate from "src/utils/roundDate";
import dayjs, { Dayjs } from "dayjs";
import { Box, styled } from "@mui/material";
import {
  Colors,
  DEFAULT_TICK_FORMAT,
  DEFAULT_CONNECTED_STATIONS_Y_AXIS_TICK_COUNT,
  DEFAULT_CONNECTED_STATIONS_Y_AXIS_TICK_MARGIN,
  DEFAULT_CONNECTED_STATIONS_VOLTAGE_TICK_MARGIN,
  DataType,
  DEFAULT_DEVICE_ID,
  MINUTE,
  UNIT_TYPE_DAY,
  DEFAULT_CONNECTED_STATIONS_SOURCE_OF_CONSUMPTION_DOMAIN,
  TICK_INTERVAL_THREE_HOURS,
} from "src/system/constants";
import { ChartItem } from "src/types/customTooltipType";
import SystemLoader from "../../../components/SystemLoader";
import {
  getColors,
  getLineVisibility,
  getYAxisVisibility,
} from "src/utils/stationsChartGetters";
import CustomTooltip from "./CustomTooltip";
import NoData from "../../../components/NoData";
import {
  useGetDataEcoflowQuery,
} from "../../../state/reducers/notificationsApi";
import getTicksOfInterval from "../../../utils/getTicksOfInterval";
import { Datatype } from "src/types/apiTypes";

const StationsCharts: React.FC<{
  date: Dayjs | null;
  stations: DataType;
}> = ({ date, stations }) => {
  const { data: DataVoltage, isLoading } = useGetDataEcoflowQuery({
    device_id: DEFAULT_DEVICE_ID,
    datatype: Datatype.BatteryManagementSystem,
    lower_bound: dayjs().startOf(UNIT_TYPE_DAY).unix(),
    upper_bound: dayjs().startOf(MINUTE).unix(),
  });

  const dataForChart = DataVoltage?.map((dataItem) => ({
    [stations]: dataItem[stations as keyof typeof dataItem],
    timestamp: dataItem.timestamp,
  })).filter((dataItem) => dataItem[stations]);
  const dataCount = dataForChart?.length;

  const ticks = getTicksOfInterval(
    DataVoltage?.[0]?.timestamp,
    DataVoltage?.[DataVoltage[0] ? DataVoltage.length - 1 : 0]?.timestamp,
    TICK_INTERVAL_THREE_HOURS
  );

  return (
    <>
      {isLoading && <SystemLoader />}
      {dataCount === 0 && <NoData />}
      {!isLoading && dataCount !== 0 && (
        <Chart>
          <ResponsiveContainer width="100%" height={200}>
            <AreaChart
              data={dataForChart ?? []}
              margin={{
                top: 10,
                right: 0,
                left: 4,
                bottom: 0,
              }}
            >
              <defs>
                <linearGradient id="colorVOL" x1="0" y1="0" x2="0" y2="1">
                  <stop
                    offset="5%"
                    stopColor={getColors(stations)}
                    stopOpacity={0.4}
                  />
                  <stop
                    offset="95%"
                    stopColor={getColors(stations)}
                    stopOpacity={0.07}
                  />
                </linearGradient>
              </defs>
              <CartesianGrid
                stroke={Colors.brightGray}
                vertical={true}
                strokeDasharray="3"
              />
              <XAxis
                dataKey="timestamp"
                type="number"
                domain={["dataMin", "dataMax"]}
                stroke={Colors.lightWhite}
                strokeDasharray="3"
                tickMargin={DEFAULT_CONNECTED_STATIONS_VOLTAGE_TICK_MARGIN}
                tick={{
                  fontSize: 12,
                  fontWeight: 500,
                  fill: Colors.lightBlack,
                }}
                ticks={ticks}
                tickFormatter={(value: number, index: number) => {
                  if (
                    stations === DataType.VOL ||
                    stations === DataType.ENERGY
                  ) {
                    return roundDate(value, DEFAULT_TICK_FORMAT);
                  }
                  return "";
                }}
              />
              <YAxis
                domain={DEFAULT_CONNECTED_STATIONS_SOURCE_OF_CONSUMPTION_DOMAIN}
                stroke={Colors.lightWhite}
                axisLine={false}
                tickLine={false}
                tickCount={DEFAULT_CONNECTED_STATIONS_Y_AXIS_TICK_COUNT}
                tickMargin={DEFAULT_CONNECTED_STATIONS_Y_AXIS_TICK_MARGIN}
                tick={{
                  fontSize: 12,
                  fontWeight: 500,
                  fill: Colors.lightBlack,
                  width: 80,
                }}
                tickFormatter={(number: number) => {
                  if (
                    stations === DataType.VOL ||
                    stations === DataType.ENERGY
                  ) {
                    return `${number.toFixed(0)} V`;
                  }
                  return "";
                }}
              />
              <Tooltip
                content={(props) => (
                  <CustomTooltip data={props as ChartItem} type={stations} />
                )}
              />
              <Area
                name={getLineVisibility(stations)}
                dataKey={getYAxisVisibility(stations)}
                stroke={getColors(stations)}
                fillOpacity={1}
                fill="url(#colorVOL)"
                activeDot={{
                  r: 6,
                  strokeWidth: 2,
                  stroke: getColors(stations),
                  fill: Colors.white,
                }}
              />
            </AreaChart>
          </ResponsiveContainer>
        </Chart>
      )}
    </>
  );
};

const Chart = styled(Box)({
  padding: "0",
});

export default StationsCharts;
