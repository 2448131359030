import dayjs from "dayjs";
import { styled, Box } from "@mui/material";
import { RangeEnergyIcon } from "src/assets/icons";
import { RangeEnergyIconActive } from "src/assets/icons";
import {
  Colors,
  DEFAULT_DEVICE_ID,
  MINUTE,
  RangeScaleAlignment,
  UNIT_TYPE_DAY,
} from "src/system/constants";
import ProgressBar from "../../../components/ProgressBar";
import {
  useGetDataEcoflowQuery,
} from "../../../state/reducers/notificationsApi";
import { Datatype } from "src/types/apiTypes";

export interface SvgType {
  energyActiveSvg?: string;
  setEnergyActiveSvg?: string;
}

const RangeEnergy = () => {
  const { data: GetDataEcoflowShowSoc } = useGetDataEcoflowQuery({
    device_id: DEFAULT_DEVICE_ID,
    datatype: Datatype.EnergyManagementSystem,
    lower_bound: dayjs().startOf(UNIT_TYPE_DAY).unix(),
    upper_bound: dayjs().startOf(MINUTE).unix(),
  });

  const lastTimestamp = parseFloat(
    Number(
      GetDataEcoflowShowSoc?.[GetDataEcoflowShowSoc?.length - 1]
        ?.lcd_show_soc ?? 0
    ).toFixed(2)
  );

  const openIcon = () => {
    if (!!lastTimestamp && lastTimestamp <= 100 && lastTimestamp >= 81) {
      return <RangeEnergyIconActive />;
    } else if (!!lastTimestamp && lastTimestamp < 80 && lastTimestamp >= 30) {
      return <RangeEnergyIconActive />;
    }
    return <RangeEnergyIcon />;
  };

  return (
    <RangeEnergyBlock>
      <RangeEnergySection>{openIcon()}</RangeEnergySection>
      <Box sx={{ height: 190 }} className="boxSlider">
        <span className="paramSlider">F</span>
        <ProgressBar
          value={lastTimestamp}
          alignment={RangeScaleAlignment.LEFT}
          units="%"
        />
        <span className="paramSlider">E</span>
      </Box>
    </RangeEnergyBlock>
  );
};

const RangeEnergyBlock = styled(Box)({
  padding: "0 0 0 0",
  margin: "-9px 0 0 0",
  position: "relative",
  height: "190px",
  width: "40px",
  "& .boxSlider .MuiSlider-thumb": {
    zIndex: "100",
  },
  "& .boxSlider": {
    display: "flex",
    justifyContent: "space-between",
  },
  "& .boxSlider .sliderEnergy .MuiSlider-valueLabel": {
    right: "30px",
  },
  "& .boxSlider .sliderEnergy .MuiSlider-valueLabelLabel:before": {
    content: "'%'",
    position: "absolute",
    top: "0",
    right: "-10px",
    width: "10px",
    height: "10px",
  },
  ".boxSlider": {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    position: "relative",
    pointerEvents: "none",
  },
  ".boxSlider::before": { content: '""', position: "absolute" },
  ".boxSlider .paramSlider": {
    fontSize: "16px",
    lineHeight: "19px",
    marginBottom: "11px",
  },
  ".boxSlider .paramSlider:last-of-type": {
    marginBottom: "0",
    marginTop: "6px",
  },
  ".boxSlider .sliderEnergy": {
    width: "2px",
    color: Colors.kinglyCloud,
  },
  ".boxSlider .MuiSlider-track": {
    backgroundColor: `${Colors.kinglyCloud} !important`,
  },
  ".boxSlider .MuiSlider-thumb": { width: "14px", height: "14px" },
  ".boxSlider .MuiSlider-valueLabelOpen::before": { display: "none" },
  ".boxSlider .sliderEnergy .MuiSlider-thumb": {
    backgroundColor: Colors.highlighterRed,
  },
  ".boxSlider .MuiSlider-thumb:hover": { boxShadow: "none" },
  ".boxSlider .sliderEnergy .MuiSlider-valueLabel": {
    backgroundColor: Colors.white,
    padding: "0",
    color: Colors.dimGray,
    right: "32px",
    top: "19px",
  },
});

const RangeEnergySection = styled(Box)({
  position: "absolute",
  top: "50%",
  right: "-12px",
  margin: "4px 0 0 0",
  transform: "translate(0, -50%)",
});

export default RangeEnergy;
