import React, { useState } from "react";
import {
  AreaChart,
  Area,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";
import roundDate from "src/utils/roundDate";
import dayjs, { Dayjs } from "dayjs";
import { Box, styled } from "@mui/material";
import SystemLoader from "../../../components/SystemLoader";
import {
  Colors,
  DEFAULT_TICK_FORMAT,
  ParamUSB,
  DEFAULT_CONNECTED_STATIONS_Y_AXIS_TICK_COUNT,
  DEFAULT_CONNECTED_STATIONS_Y_AXIS_TICK_MARGIN,
  DEFAULT_CONNECTED_STATIONS_SOURCE_OF_CONSUMPTION_DOMAIN,
  DEFAULT_DEVICE_ID,
  UNIT_TYPE_DAY,
  MINUTE,
  TICK_INTERVAL_THREE_HOURS,
} from "src/system/constants";
import CustomTooltip from "./CustomTooltipConnectedStationsSource";
import { DataKey } from "recharts/types/util/types";
import { lineVisibilityOptions } from "src/types/appTypes";
import NoData from "../../../components/NoData";
import {
  useGetDataEcoflowQuery,
} from "../../../state/reducers/notificationsApi";
import SourcesConsumptionRenderLegend from "./SourcesConsumptionRenderLegend";
import getTicksOfInterval from "../../../utils/getTicksOfInterval";
import { Datatype } from "src/types/apiTypes";

const ConnectedStationsSourcesOfConsumptionChart: React.FC<{
  date: Dayjs | null;
}> = ({ date }) => {
  const { data: DataConsumption, isLoading } = useGetDataEcoflowQuery({
    device_id: DEFAULT_DEVICE_ID,
    datatype: Datatype.PowerDelivery,
    lower_bound: dayjs().startOf(UNIT_TYPE_DAY).unix(),
    upper_bound: dayjs().startOf(MINUTE).unix(),
  });

  const [lineVisibilityOptions, setLineVisibilityOptions] =
    useState<lineVisibilityOptions>({
      ParamUSB: true,
    });

  const handleLegendClick = (DataConsumption: {
    dataKey?: DataKey<any> | undefined;
  }) => {
    setLineVisibilityOptions({
      ...lineVisibilityOptions,
      [DataConsumption.dataKey as string]:
        !lineVisibilityOptions[DataConsumption.dataKey as string],
    });
  };

  const dataForChart = DataConsumption?.map((dataItem) => ({
    usb_1_watts: dataItem.usb_1_watts,
    car_watts: dataItem.car_watts,
    type_c_1_watts: dataItem.type_c_1_watts,
    timestamp: dataItem.timestamp,
  })).filter(
    (dataItem) =>
      dataItem.usb_1_watts || dataItem.car_watts || dataItem.type_c_1_watts
  );

  const dataCount = dataForChart?.length;

  const ticks = getTicksOfInterval(
    DataConsumption?.[0]?.timestamp,
    DataConsumption?.[DataConsumption[0] ? DataConsumption.length - 1 : 0]
      ?.timestamp,
    TICK_INTERVAL_THREE_HOURS
  );

  return (
    <>
      {isLoading && <SystemLoader />}
      {dataCount === 0 && <NoData />}
      {!isLoading && dataCount !== 0 && (
        <ActiveReactive>
          <div className="activeReactive">
            <ResponsiveContainer width="100%" height={200}>
              <AreaChart
                data={DataConsumption ?? []}
                margin={{
                  top: -16,
                  right: 0,
                  left: -22,
                  bottom: 0,
                }}
              >
                <defs>
                  <linearGradient id="colorUSB" x1="0" y1="0" x2="0" y2="1">
                    <stop
                      offset="1%"
                      stopColor={Colors.lightSkyYellow}
                      stopOpacity={0.8}
                    />
                    <stop
                      offset="95%"
                      stopColor={Colors.lightSkyYellow}
                      stopOpacity={0.3}
                    />
                  </linearGradient>
                  <linearGradient id="colorCar" x1="0" y1="0" x2="0" y2="1">
                    <stop
                      offset="1%"
                      stopColor={Colors.brightBlue}
                      stopOpacity={0.8}
                    />
                    <stop
                      offset="95%"
                      stopColor={Colors.brightBlue}
                      stopOpacity={0.3}
                    />
                  </linearGradient>
                  <linearGradient id="colorTypeC" x1="0" y1="0" x2="0" y2="1">
                    <stop
                      offset="1%"
                      stopColor={Colors.lightBrightGreen}
                      stopOpacity={0.8}
                    />
                    <stop
                      offset="95%"
                      stopColor={Colors.lightBrightGreen}
                      stopOpacity={0.3}
                    />
                  </linearGradient>
                </defs>
                <CartesianGrid
                  stroke={Colors.brightGray}
                  vertical={true}
                  strokeDasharray="3"
                />
                <XAxis
                  dataKey="timestamp"
                  type="number"
                  domain={["dataMin", "dataMax"]}
                  stroke={Colors.lightWhite}
                  strokeDasharray="3"
                  tickMargin={10}
                  tick={{
                    fontSize: 12,
                    fontWeight: 500,
                    fill: Colors.lightBlack,
                  }}
                  ticks={ticks}
                  tickFormatter={(value: number, index: number) =>
                    roundDate(value, DEFAULT_TICK_FORMAT)
                  }
                />
                <YAxis
                  stroke={Colors.lightWhite}
                  domain={
                    DEFAULT_CONNECTED_STATIONS_SOURCE_OF_CONSUMPTION_DOMAIN
                  }
                  axisLine={false}
                  tickLine={false}
                  tickCount={DEFAULT_CONNECTED_STATIONS_Y_AXIS_TICK_COUNT}
                  tickMargin={DEFAULT_CONNECTED_STATIONS_Y_AXIS_TICK_MARGIN}
                  tick={{
                    fontSize: 10,
                    fontWeight: 500,
                    fill: Colors.lightBlack,
                  }}
                  tickFormatter={(number) => `${number.toFixed(0)}W`}
                />
                <Tooltip content={<CustomTooltip />} />
                <Legend
                  content={
                    <SourcesConsumptionRenderLegend data={dataForChart} />
                  }
                  onClick={handleLegendClick}
                  style={{
                    position: "absolute",
                    top: 0,
                    right: 0,
                    opacity: "0.5",
                  }}
                  layout="vertical"
                  verticalAlign="top"
                  className="colorActive"
                />
                <Area
                  name={ParamUSB.USB_C}
                  type="monotone"
                  dataKey="usb_1_watts"
                  stroke={`url(#colorUSB)`}
                  fill={`url(#colorUSB)`}
                  strokeWidth="2"
                  isAnimationActive={false}
                  animationDuration={0}
                  activeDot={{
                    r: 6,
                    stroke: Colors.lightSkyYellow,
                    fill: "transparent",
                  }}
                />

                <Area
                  name={ParamUSB.USB_F_C}
                  type="monotone"
                  dataKey="car_watts"
                  stroke={`url(#colorCar)`}
                  fill={`url(#colorCar)`}
                  strokeWidth="2"
                  isAnimationActive={false}
                  animationDuration={0}
                  activeDot={{
                    r: 6,
                    stroke: Colors.brightBlue,
                    fill: "transparent",
                  }}
                />
                <Area
                  name={ParamUSB.TYPE_ONE_C}
                  type="monotone"
                  dataKey="type_c_1_watts"
                  stroke={`url(#colorTypeC)`}
                  fill={`url(#colorTypeC)`}
                  strokeWidth="2"
                  isAnimationActive={false}
                  animationDuration={0}
                  activeDot={{
                    r: 6,
                    stroke: Colors.lightBrightGreen,
                    fill: "transparent",
                  }}
                />
              </AreaChart>
            </ResponsiveContainer>
          </div>
        </ActiveReactive>
      )}
    </>
  );
};

const ActiveReactive = styled(Box)(({ theme }) => ({
  padding: "0 0 0 10px",
  fontWeight: "700",
  "& .activeReactive .recharts-legend-wrapper": {
    position: "absolute !important",
    top: "-35px !important",
    right: "0 !important",
    left: "auto !important",
  },
  "& .activeReactive .recharts-default-legend li": {
    position: "relative",
    padding: "7px 0",
    marginRight: "8px",
    opacity: "0.5",
    width: "56px",
  },
  "& .activeReactive .recharts-default-legend li:last-of-type": {
    marginRight: "0 !important",
  },
  "& .activeReactive .recharts-default-legend li span": {
    fontSize: "10px",
    lineHeight: "12px",
  },
  [theme.breakpoints.down("xl")]: {
    "& .activeReactive .recharts-legend-wrapper": {
      top: "-31px !important",
      right: "0 !important",
    },
    "& .activeReactive .recharts-default-legend li": {
      width: "46px",
      margin: "0 0 0 2px !important",
    },
    "& .connectedStations__box .recharts-legend-wrapper li span": {
      fontSize: "11px",
    },
  },
  [theme.breakpoints.down("lg")]: {
    "& .activeReactive .recharts-legend-wrapper": {
      top: "-30px !important",
      right: "0 !important",
    },
    "& .activeReactive .recharts-default-legend li": {
      width: "44px",
      margin: "0 0 0 2px !important",
    },
    "& .activeReactive .recharts-default-legend li span": {
      fontSize: "11px !important",
    },
  },
}));

export default ConnectedStationsSourcesOfConsumptionChart;
