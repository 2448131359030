import CustomTab from "./CustomTab";
import CustomTabList from "./CustomTabList";

const CalendarTablist = () => {
  return (
    <CustomTabList>
      <CustomTab data-testid="tab-day">Day</CustomTab>
      <CustomTab data-testid="tab-week">Week</CustomTab>
      <CustomTab data-testid="tab-month">Month</CustomTab>
      <CustomTab data-testid="tab-year">
        Year
      </CustomTab>
    </CustomTabList>
  );
};

export default CalendarTablist;
