export interface GetChartDataItem {
  amp?: number;
  input_watts?: number;
  timestamp: number;
  watts_out_sum?: number;
  watts_in_sum?: number;
  in_watts?: number;
  vol?: number;
  soc?: number;
  chg_remain_time?: number;
  temperature?: number | undefined;
  temp?: number | undefined;
  co2?: number;
  voc?: number;
  humidity?: number;
  radon_st_avg?: number;
  pressure?: number;
  aggregated_data?: number;
  lowest_value?: number;
  highest_value?: number;
  usb_1_watts?: number;
  usb_2_watts?: number;
  type_1_watts?: number;
  type_c_1_watts?: number;
  type_c_2_watts?: number;
  car_watts?: number;
  out_amp?: number;
  cycles?: number;
  dsg_remain_time?: number;
  design_cap?: number;
  full_cap?: number;
  in_vol?: number;
}

export interface DeviceData {
  device_id: string;
  datatype: string;
  lower_bound: number;
  upper_bound: number;
}

export enum Datatype {
  PowerDelivery = "PowerDelivery",
  BatteryManagementSystem = "BatteryManagementSystem",
  EnergyManagementSystem = "EnergyManagementSystem",
  MaximumPowerPointTracking = "MaximumPowerPointTracking",
  Inverter = "Inverter",
}

export interface ProducedConsumedEnergyData {
  produced_power: number;
  consumed_power: number;
}

export interface EnergySourceConsumedData {
  usb_c_100w: number,
  ac_250v: number,
  usb_a: number
}

export interface EcoflowData {
  id?: string;
  model?: string;
  timestamp?: number;
  datatype?: string;
  soc?: number;
  design_cap?: number;
  full_cap?: number;
  remain_cap?: number;
  cycles?: number;
  temp?: number;
  min_cell_temp?: number;
  max_cell_temp?: number;
  vol?: number;
  min_cell_vol?: number;
  max_cell_vol?: number;
  input_watts?: number;
  output_watts?: number;
  amp?: number;

  in_watts?: number;
  in_vol?: number;
  chg_state?: number;
  chg_remain_time?: number;
  dsg_remain_time?: number;
  watts_out_sum?: number;
  type_c_1_watts?: number;
  car_watts?: number;
  usb_1_watts?: number;
  inv_out_vol?: number;
  lcd_show_soc?: number;
  watts_in_sum?: number;
}

export interface GetRadarDataItem {
  lowest_value: {
    co2?: number;
    voc?: number;
    humidity?: number;
    pressure?: number;
    temperature?: number;
    radon_st_avg?: number;
  };
  highest_value: {
    co2?: number;
    voc?: number;
    humidity?: number;
    pressure?: number;
    temperature?: number;
    radon_st_avg?: number;
  };
  aggregated_data: {
    co2: number;
    voc: number;
    humidity: number;
    pressure: number;
    temperature: number;
    radon_st_avg: number;
    timestamp: number;
  };
  first_week: {
    lowest_value: {
      co2?: number;
      voc?: number;
      humidity?: number;
      pressure?: number;
      temperature?: number;
      radon_st_avg?: number;
    };
    highest_value: {
      co2?: number;
      voc?: number;
      humidity?: number;
      pressure?: number;
      temperature?: number;
      radon_st_avg?: number;
    };
    aggregated_data: {
      co2?: number;
      voc?: number;
      humidity?: number;
      pressure?: number;
      temperature?: number;
      radon_st_avg?: number;
      timestamp?: number;
    };
  };
  second_week: {
    lowest_value: {
      co2?: number;
      voc?: number;
      humidity?: number;
      pressure?: number;
      temperature?: number;
      radon_st_avg?: number;
    };
    highest_value: {
      co2?: number;
      voc?: number;
      humidity?: number;
      pressure?: number;
      temperature?: number;
      radon_st_avg?: number;
    };
    aggregated_data: {
      co2?: number;
      voc?: number;
      humidity?: number;
      pressure?: number;
      temperature?: number;
      radon_st_avg?: number;
      timestamp?: number;
    };
  };
  third_week: {
    lowest_value: {
      co2?: number;
      voc?: number;
      humidity?: number;
      pressure?: number;
      temperature?: number;
      radon_st_avg?: number;
    };
    highest_value: {
      co2?: number;
      voc?: number;
      humidity?: number;
      pressure?: number;
      temperature?: number;
      radon_st_avg?: number;
    };
    aggregated_data: {
      co2?: number;
      voc?: number;
      humidity?: number;
      pressure?: number;
      temperature?: number;
      radon_st_avg?: number;
      timestamp?: number;
    };
  };
  fourth_week: {
    lowest_value: {
      co2?: number;
      voc?: number;
      humidity?: number;
      pressure?: number;
      temperature?: number;
      radon_st_avg?: number;
    };
    highest_value: {
      co2?: number;
      voc?: number;
      humidity?: number;
      pressure?: number;
      temperature?: number;
      radon_st_avg?: number;
    };
    aggregated_data: {
      co2?: number;
      voc?: number;
      humidity?: number;
      pressure?: number;
      temperature?: number;
      radon_st_avg?: number;
      timestamp?: number;
    };
  };
  fifth_week: {
    lowest_value: {
      co2?: number;
      voc?: number;
      humidity?: number;
      pressure?: number;
      temperature?: number;
      radon_st_avg?: number;
    };
    highest_value: {
      co2?: number;
      voc?: number;
      humidity?: number;
      pressure?: number;
      temperature?: number;
      radon_st_avg?: number;
    };
    aggregated_data: {
      co2?: number;
      voc?: number;
      humidity?: number;
      pressure?: number;
      temperature?: number;
      radon_st_avg?: number;
      timestamp?: number;
    };
  };
}
