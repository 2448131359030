import Calendar from "./CalendarPressure";
import { TabProps } from "src/types/dashboardTypes";
import { Box, styled } from "@mui/material";
import Zone from "./Zone";
import { ReactComponent as PressurePicIcon } from "src/assets/icons/PressurePicIcon.svg";

const PressureSensor: React.FC<TabProps> = (props: TabProps) => {
  return (
    <Box>
      <PressureBlockStyled>
        <Zone
          tab={props.tab}
          setTab={props.setTab}
          name="Pressure"
          image={<PressurePicIcon />}
          type="pressure"
        />
        <Calendar date={props.date} setDate={props.setDate} />
      </PressureBlockStyled>
    </Box>
  );
};

const PressureBlockStyled = styled(Box)(({ theme }) => ({
  display: "flex",
  [theme.breakpoints.down("sm")]: {
    flexDirection: "column",
  },
}));

export default PressureSensor;
