import useGetStatusData from "src/store/useGetStatusData";
import { styled, Box, Typography, List, ListItem } from "@mui/material";
import {
  DeviceStatus,
  SensorStatus,
  Colors,
  HIGH_PERCENT_LEVEL_BATTERY,
  LOW_PERCENT_LEVEL_BATTERY,
  DEFAULT_DEVICE_ID,
  HOUR,
  HOURS_TO_SUBTRACT,
  MINUTE,
  PERCENTAGE_MULTIPLIER,
  LEADING_ZERO_THRESHOLD,
  DECIMAL_PLACES,
} from "src/system/constants";
import {
  useGetDataEcoflowQuery,
} from "../../../state/reducers/notificationsApi";
import dayjs from "dayjs";
import { splitMinutes } from "../../../utils/getSplitMinutes";
import { Datatype } from "src/types/apiTypes";

const TIME_TO_UNCHARGE = "Time to Uncharge";
const TIME_TO_FULL_CHARGE = "Time to Full charge";

const ActiveDevice = () => {
  const { data: dataEcoflow } = useGetDataEcoflowQuery({
    device_id: DEFAULT_DEVICE_ID,
    datatype: Datatype.BatteryManagementSystem,
    lower_bound: dayjs()
      .subtract(HOURS_TO_SUBTRACT, HOUR)
      .startOf(MINUTE)
      .unix(),
    upper_bound: dayjs().startOf(MINUTE).unix(),
  });

  const { data: GetDataCounter } = useGetDataEcoflowQuery({
    device_id: DEFAULT_DEVICE_ID,
    datatype: Datatype.EnergyManagementSystem,
    lower_bound: dayjs()
      .subtract(HOURS_TO_SUBTRACT, HOUR)
      .startOf(MINUTE)
      .unix(),
    upper_bound: dayjs().startOf(MINUTE).unix(),
  });

  const totalFullCap =
    dataEcoflow?.reduce((acc, value) => acc + (value?.full_cap ?? 0), 0) ?? 0;

  const totalDesignCap =
    dataEcoflow?.reduce((acc, value) => acc + (value?.design_cap ?? 0), 0) ?? 0;

  //we need to get the sum of all arrays with percentages
  const maximumCapacity =
    totalDesignCap !== 0 && !isNaN(totalFullCap / totalDesignCap)
      ? (totalFullCap / totalDesignCap) * PERCENTAGE_MULTIPLIER
      : 0;

  const {
    vol: dataVoltage = 0,
    cycles: dataCycles = 0,
    amp: dataAmp = 0,
    temp: dataTemperature = 0,
    soc: dataSoc = 0,
  } = dataEcoflow?.[dataEcoflow?.length - 1] ?? {};

  const checkColor = (dataSoc: number): string => {
    if (dataSoc >= HIGH_PERCENT_LEVEL_BATTERY) {
      return Colors.greenMana;
    } else if (
      dataSoc < HIGH_PERCENT_LEVEL_BATTERY &&
      dataSoc >= LOW_PERCENT_LEVEL_BATTERY
    ) {
      return Colors.peachCobbler;
    } else if (dataSoc < LOW_PERCENT_LEVEL_BATTERY) {
      return Colors.highlighterRed;
    } else {
      return Colors.highlighterRed;
    }
  };

  const allDevices = useGetStatusData();

  const status =
    allDevices.data.device?.[1]?.device_status === DeviceStatus.ON &&
    !!dataEcoflow?.length;
  const checkColorStatus = (status: boolean) => {
    return status ? Colors.greenMana : Colors.highlighterRed;
  };

  const lastDataCounter = GetDataCounter?.[GetDataCounter.length - 1];

  const chg_state = lastDataCounter?.chg_state ?? 0;

  const chg_remain_time = lastDataCounter?.chg_remain_time ?? 0;

  const dsg_remain_time = lastDataCounter?.dsg_remain_time ?? 0;

  const accountTime = () => {
    if (chg_state === 0 || chg_state === 2) {
      return dsg_remain_time;
    } else {
      return chg_remain_time;
    }
  };

  const chargingMessage =
    chg_state === 0 || chg_state === 2 ? TIME_TO_UNCHARGE : TIME_TO_FULL_CHARGE;

  const time = accountTime();
  const { days, hours, minutes } = splitMinutes(time);

  const daysToUnCharge = days < LEADING_ZERO_THRESHOLD ? `0${days}` : `${days}`;
  const hoursToUnCharge =
    hours < LEADING_ZERO_THRESHOLD ? `0${hours}` : `${hours}`;
  const minutesToUnCharge =
    minutes < LEADING_ZERO_THRESHOLD ? `0${minutes}` : `${minutes}`;

  return (
    <ActiveDeviceStyled className="device-block">
      <Typography className="SocketData">
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <Box>
            <TitleActiveDevice>Active Device</TitleActiveDevice>
            <SubtitleTypography>EcoFlow RiverTwo</SubtitleTypography>
          </Box>
          <ActiveDeviceItems>
            <ActiveDeviceMessage>{chargingMessage}</ActiveDeviceMessage>
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
              }}
            >
              <TimerValueTypography>
                {daysToUnCharge} <Duration>Days</Duration>
              </TimerValueTypography>
              <TimerValueTypography
                sx={{
                  fontSize: "16px",
                  fontWeight: "400",
                }}
              >
                <Colon>:</Colon>
              </TimerValueTypography>
              <TimerValueTypography>
                {hoursToUnCharge} <Duration>Hours</Duration>
              </TimerValueTypography>
              <TimerValueTypography
                sx={{
                  fontSize: "16px",
                  fontWeight: "400",
                }}
              >
                <ColonLast>:</ColonLast>
              </TimerValueTypography>
              <TimerValueTypography>
                {minutesToUnCharge.split(".")[0]} <Duration>Minutes</Duration>
              </TimerValueTypography>
            </Box>
          </ActiveDeviceItems>
        </Box>
        <EcoFlowList>
          <List className="EcoFlow__list">
            <StyledListItem>
              <ActiveDeviceNameParametr>Status</ActiveDeviceNameParametr>
              <ItemValueTypography
                className="status__active"
                style={{ color: checkColorStatus(status) }}
              >
                {status ? SensorStatus.ACTIVE : SensorStatus.INACTIVE}
              </ItemValueTypography>
            </StyledListItem>
            <StyledListItem>
              <ActiveDeviceNameParametr>Charge level</ActiveDeviceNameParametr>
              <ItemValueTypography
                className="charge__level"
                style={{
                  color: checkColor(dataSoc),
                }}
              >
                {dataSoc.toFixed(DECIMAL_PLACES)}%
              </ItemValueTypography>
            </StyledListItem>
            <StyledListItem>
              <ActiveDeviceNameParametr>Temperature</ActiveDeviceNameParametr>
              <ItemValueTypography>
                {dataTemperature.toFixed(DECIMAL_PLACES)}
                °C
              </ItemValueTypography>
            </StyledListItem>
            <StyledListItem>
              <ActiveDeviceNameParametr>Amperage</ActiveDeviceNameParametr>
              <ItemValueTypography>
                {dataAmp.toFixed(DECIMAL_PLACES)}A
              </ItemValueTypography>
            </StyledListItem>
            <StyledListItem>
              <ActiveDeviceNameParametr>Cycles</ActiveDeviceNameParametr>
              <ItemValueTypography>
                {dataCycles.toFixed(DECIMAL_PLACES)}
              </ItemValueTypography>
            </StyledListItem>
            <StyledListItem>
              <ActiveDeviceNameParametr>Voltage</ActiveDeviceNameParametr>
              <ItemValueTypography>
                {dataVoltage.toFixed(DECIMAL_PLACES)}V
              </ItemValueTypography>
            </StyledListItem>
            <StyledListItem>
              <ActiveDeviceNameParametr>
                Maximum Capacity
              </ActiveDeviceNameParametr>
              <ItemValueTypography>
                {maximumCapacity.toFixed(DECIMAL_PLACES)}%
              </ItemValueTypography>
            </StyledListItem>
          </List>
        </EcoFlowList>
      </Typography>
    </ActiveDeviceStyled>
  );
};

const TimerValueTypography = styled(Typography)({
  textAlign: "center",
  fontFamily: "Roboto",
  fontSize: "20px",
  fontStyle: "normal",
  fontWeight: 600,
  lineHeight: "normal",
  color: Colors.gray,
});
const ItemValueTypography = styled(Typography)({
  textAlign: "right",
  fontFamily: "Roboto",
  fontSize: "14px",
  fontStyle: "normal",
  fontWeight: 400,
  lineHeight: "normal",
  textTransform: "capitalize",
});
const StyledListItem = styled(ListItem)({
  display: "flex",
  justifyContent: "space-between !important",
  padding: "0px",
});
const SubtitleTypography = styled(Typography)({
  fontFamily: "Roboto",
  fontSize: "14px",
  fontStyle: "normal",
  fontWeight: 400,
  lineHeight: "normal",
  alignItems: "center",
  color: Colors.dimGray,
  marginTop: "11px",
  marginBottom: "31px",
});
const ActiveDeviceStyled = styled(Box)(({ theme }) => ({
  width: "29%",
  padding: "24px",
  ".Switches .SocketData .react-tabs__tab-list": {
    display: "flex",
    padding: "9px 0 0 0",
    borderBottom: `1px solid ${Colors.lightWhite}`,
    margin: ["0", "0 12px 0 12px"],
  },
  ".Switches .SocketData .react-tabs__tab": {
    padding: "17px 0 20px 0",
    width: "100%",
    maxWidth: "50%",
    margin: "0 0 0 0",
    border: "none !important",
    borderTop: `2px solid ${Colors.white}`,
    borderBottom: `2px solid ${Colors.white}`,
    borderRight: `1px solid ${Colors.lightWhite} !important`,
    fontSize: "18px",
    lineHeight: "20px",
    color: Colors.gray,
    fontWeight: 500,
    display: "flex",
    justifyContent: "center",
    bottom: "0 !important",
    textAlign: "center",
    borderRadius: "0",
    background: "none",
    outline: "none",
    position: "static",
  },
  ".Switches .SocketData .react-tabs__tab:last-child": {
    borderRight: "none !important",
  },
  ".Switches .SocketData .react-tabs__tab.react-tabs__tab--selected": {
    borderBottom: `2px solid ${Colors.lightGreen} !important`,
    color: Colors.lightBlack,
  },
  ".styles-main": {
    position: "relative",
    width: "100px",
    height: "16px",
    cursor: "pointer",
    borderRadius: "5px",
    marginBottom: "40px",
  },
  ".styles-fill": {
    position: "absolute",
    top: "0",
    left: "0",
    width: "100%",
    height: "100%",
    background: [
      "rgb(245, 248, 255)",
      "linear-gradient(\n    79deg,\n    rgba(245, 248, 255, 1) 0%,\n    rgba(180, 233, 205, 1) 39%,\n    rgba(159, 189, 249, 1) 100%\n  )",
    ],
    borderRadius: "5px",
  },
  ".styles-content": {
    position: "absolute",
    top: "-20px",
    right: "0",
    color: Colors.dartGray,
  },
  ".styles-container": { height: "100%" },
  ".EcoFlow": {
    fontSize: "18px",
    lineHeight: "20px",
    color: Colors.dimGray,
    display: "flex",
    alignItems: "center",
    marginBottom: "12px",
  },
  ".EcoFlow svg": { margin: "0 16px 0 0" },
  "ul.EcoFlow__list": {
    listStyle: "none",
  },
  "ul.EcoFlow__list li": {
    fontSize: "14px",
    lineHeight: "16px",
    color: Colors.dimGray,
    margin: "0 0 16px 0",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    paddingLeft: "0",
  },
  "ul.EcoFlow__list li b": { fontWeight: 500, flex: "1 0 1" },
  "ul.EcoFlow__list li span": { flex: "0 0 70px" },
  ".SocketData h2": { marginBottom: "16px" },
  [theme.breakpoints.down("md")]: {
    "& .SocketData h2": {
      padding: "0 0 0 15px",
    },
    width: "100%",
    "ul.EcoFlow__list li": {
      justifyContent: "flex-start",
      fontSize: "14px",
      fontWeight: "500",
    },
    "ul.EcoFlow__list li p": {
      fontSize: "14px",
      fontWeight: "500",
    },
  },
}));

const EcoFlowList = styled(Box)(({ theme }) => ({
  padding: "0",
  "& ul": {
    padding: "0",
  },
  "& ul li": {
    justifyContent: "flex-start",
    alignItems: "flex-start",
    "& span": {
      "&::first-letter": {
        textTransform: "uppercase",
      },
    },
  },
  "& ul li b": {
    width: "50%",
    display: "block",
  },

  [theme.breakpoints.down("lg")]: {
    "& ul": {
      padding: "0",
    },
    "& ul li": {
      fontSize: "12px",
    },
    "& ul li b": {
      width: "70%",
    },
    "& ul li p": {
      fontWeight: "500",
      fontSize: "18px",
    },
  },
  [theme.breakpoints.down("md")]: {
    "& ul li b": {
      width: "60%",
      margin: "0 10px 0 0",
    },
  },
}));

const TitleActiveDevice = styled(Box)({
  fontWeight: "500",
  fontSize: "18px",
  height: "18px",
  lineHeight: "unset",
});

const ActiveDeviceNameParametr = styled(Box)({
  fontWeight: "500",
  fontSize: "14px",
  width: "50%",
});

const Duration = styled(Box)({
  padding: "0",
  fontSize: "11px",
  lineHeight: "14px",
  fontWeight: "400",
});

const Colon = styled(Box)({
  width: "15px",
  padding: "2px 0 0 0",
});

const ColonLast = styled(Box)({
  width: "10px",
  padding: "2px 0 0 0",
});

const ActiveDeviceItems = styled(Box)(({ theme }) => ({
  width: "153px",
  height: "73px",
  background: Colors.grayLight,
  marginTop: "-24px",
  marginRight: "-24px",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  flexDirection: "column",
  gap: "6px",
  [theme.breakpoints.down("lg")]: {
    marginRight: "-30px",
  },
  [theme.breakpoints.down("md")]: {
    marginRight: "-24px",
  },
}));
const ActiveDeviceMessage = styled(Typography)({
  textAlign: "center",
  fontFamily: "Roboto",
  fontSize: "10px",
  fontStyle: "normal",
  fontWeight: 500,
  lineHeight: "normal",
  letterSpacing: "-0.1px",
  textTransform: "uppercase",
  color: Colors.lightGray2,
});

export default ActiveDevice;
