import React, { useState } from "react";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import "react-tabs/style/react-tabs.css";
import Humidity from "./Humidity";
import PollutionSensor from "./PollutionSensor";
import TempSensor from "./TempSensor";
import PressureSensor from "./PressureSensor";
import MotionSensor from "./MotionSensor";
import Energy from "./Energy";
import dayjs, { Dayjs } from "dayjs";
import { Box, Link, styled } from "@mui/material";
import EnergySource from "./EnergySource";
import ActiveDevice from "./ActiveDevice";
import AirQualityMonitor from "./AirQualityMonitor";
import {
  Colors,
  DEFAULT_DATE_FORMAT,
  DevicesIds,
  DeviceTypes,
  UnitTypes,
  DEFAULT_FORMAT_TIME,
  DINAMICKA_DEVELOPMENT_URL,
  tabsBtn,
} from "src/system/constants";
import { postApi } from "src/state/reducers/postApi";

const Switches = () => {
  const [tab, setTab] = useState(0);
  const currentDate = dayjs().format(DEFAULT_FORMAT_TIME);
  const baseDate = dayjs(
    dayjs().add(1, "day").format(DEFAULT_DATE_FORMAT),
    DEFAULT_DATE_FORMAT
  );
  const [date, setDate] = React.useState<Dayjs | null>(dayjs);

  const { data } = postApi.useGetChartDataQuery({
    deviceId: DevicesIds.WAVE_PLUS_ID,
    deviceType: DeviceTypes.WAVE_PLUS,
    unitType: UnitTypes.DAY,
    dateStart: dayjs(baseDate).subtract(1, "day").unix(),
    dateEnd: baseDate.unix(),
  });

  const currentTemperature =
    data?.items?.[data?.items.length - 1]?.temperature?.toFixed(0);

  return (
    <SwitchesStyled>
      <div className="Switches">
        <Tabs selectedIndex={tab} onSelect={(idx) => setTab(idx)}>
          <TabList>
            {tabsBtn.map(({ title, imgComponent, dataTestId }) => {
              const { icon: Icon, className } = imgComponent;
              return (
                <Tab key={title} data-testid={dataTestId}>
                  <span>
                    {Icon && <Icon className={className} />}
                    {title}
                  </span>
                </Tab>
              );
            })}
          </TabList>
          <TabPanel>
            <Energy setTab={setTab} tab={tab} date={date} setDate={setDate} />
          </TabPanel>
          <TabPanel>
            <PollutionSensor setTab={setTab} tab={tab} date={date} setDate={setDate} />
          </TabPanel>
          <TabPanel>
            <Humidity setTab={setTab} tab={tab} date={date} setDate={setDate} />
          </TabPanel>
          <TabPanel>
            <TempSensor setTab={setTab} tab={tab} date={date} setDate={setDate} />
          </TabPanel>
          <TabPanel>
            <PressureSensor setTab={setTab} tab={tab} date={date} setDate={setDate} />
          </TabPanel>
          <TabPanel>
            <MotionSensor setTab={setTab} tab={tab} date={date} setDate={setDate} />
          </TabPanel>
        </Tabs>
        <WrapDevicesBottom>
          <EnergySource date={date} />
          <ActiveDevice />
          <AirQualityMonitor date={date} />
        </WrapDevicesBottom>
        <HumidityBottom>
          <CreatedBy>
            <Link href={DINAMICKA_DEVELOPMENT_URL} target="_blank">
              Created by
              <span>Dinamicka Development</span>
            </Link>
          </CreatedBy>
          <HumidityBottomTemperature>
            {currentTemperature}
            °C <HumidityBottomTemperatureLine>|</HumidityBottomTemperatureLine>
            Current office temperature
          </HumidityBottomTemperature>
          <HumidityBottomData>{currentDate}</HumidityBottomData>
        </HumidityBottom>
      </div>
    </SwitchesStyled>
  );
};

const SwitchesStyled = styled(Box)(({ theme }) => ({
  ".Switches": { padding: "19px 0 0 0" },
  ".Switches .react-tabs__tab-list": {
    display: "flex",
    borderBottom: "none",
    margin: "0",
  },
  ".Switches .react-tabs__tab": {
    padding: "0",
    width: "100%",
    maxWidth: "147px",
    margin: "0 11px 0 0",
    border: `1px solid ${Colors.whiteSky} !important`,
    fontSize: "16px",
    lineHeight: "18px",
    color: Colors.silver,
    display: "flex",
    justifyContent: "center",
    bottom: "0 !important",
    textAlign: "center",
    borderRadius: "0",
    background: "none",
    outline: "none",
    position: "static",
    [theme.breakpoints.down("lg")]: {
      marginBottom: "10px",
      maxWidth: "128px",
      minWidth: "128px",
    },
  },
  ".react-tabs__tab img": {
    width: "20px",
    height: "20px",
    position: "relative",
    left: "-7px",
    top: "-5%",
  },
  ".react-tabs__tab.react-tabs__tab--selected img polygon": { stroke: "red" },
  ".Switches .react-tabs__tab span:before": {
    content: '""',
    position: "absolute",
    top: "-10px",
    left: "0",
    background:
      "linear-gradient(\n    90deg,\n    rgba(224, 224, 224, 1) 0%,\n    rgba(237, 237, 239, 1) 51%,\n    rgba(247, 247, 249, 1) 100%\n  )",
    clipPath: "polygon(0 0, 70% 0, 100% 100%, 0% 100%)",
    width: "68px",
    height: "9px",
    zIndex: 10,
  },
  ".Switches .react-tabs__tab span": {
    border: "1px solid transparent",
    position: "relative",
    display: ["block", "flex"],
    width: "100%",
    padding: "10px 0 9px 0",
    justifyContent: "center",
    alignItems: "center",
  },
  ".Switches .react-tabs__tab.react-tabs__tab--selected span": {
    border: `1px solid ${Colors.skyBlue}`,
    color: Colors.dimGray,
  },
  ".Switches .react-tabs__tab.react-tabs__tab--selected span:before": {
    content: '""',
    position: "absolute",
    top: "-10px",
    left: "0",
    background: ["var(--main-background)", "var(--main-gradient)"],
    clipPath: "polygon(0 0, 70% 0, 100% 100%, 0% 100%)",
    width: "68px",
    height: "9px",
    zIndex: 10,
  },
  ".Switches .react-tabs__tab span svg": { marginRight: "8px" },
  ".Switches .react-tabs__tab span svg *": { stroke: Colors.silver },
  ".Switches .react-tabs__tab.react-tabs__tab--selected span svg.energySvg *": {
    stroke: Colors.lightSkyYellow,
  },
  ".Switches .react-tabs__tab.react-tabs__tab--selected span svg.humiditySvg *":
    {
      stroke: Colors.skyBlue,
    },
  ".Switches .react-tabs__tab.react-tabs__tab--selected span svg.pollutionSvg *":
    {
      stroke: Colors.dimGray,
    },
  ".Switches .react-tabs__tab.react-tabs__tab--selected span svg.tempSvg *": {
    stroke: Colors.orange,
  },
  ".Switches .react-tabs__tab.react-tabs__tab--selected span svg.pressureSvg *":
    {
      stroke: Colors.dimGray,
    },
  ".Switches .react-tabs__tab.react-tabs__tab--selected span svg.radonSvg .path":
    {
      fill: Colors.heavenly,
    },
  ".Switches .react-tabs__tab.react-tabs__tab--selected span svg.radonSvg *": {
    stroke: Colors.lightSkyBlue,
  },
  ".Switches .device-block": {
    boxShadow: "0 0 3px #CCCCCC",
    "&:nth-child(1)": {
      padding: "24px",
      flex: "1 0 445px",
      flexBasis: "auto",
      [theme.breakpoints.down("lg")]: {
        flexBasis: "calc(50% - 15px)",
      },
      [theme.breakpoints.down("sm")]: {
        flexBasis: "100%",
      },
    },
    "&:nth-child(2)": {
      padding: "24px",
      flex: "1 0 329px",
      flexBasis: "auto",
      [theme.breakpoints.down("lg")]: {
        padding: "24px 30px 28px 24px",
        flexBasis: "calc(50% - 15px)",
      },
      [theme.breakpoints.down("md")]: {
        padding: "24px",
      },
      [theme.breakpoints.down("sm")]: {
        flexBasis: "100%",
      },
    },
    "&:nth-child(3)": {
      padding: "24px",
      flex: "1 0 329px",
      maxWidth: "500px",
      flexBasis: "auto",
      [theme.breakpoints.down("lg")]: {
        maxWidth: "calc(50% - 10px)",
        flexBasis: "calc(50% - 15px)",
      },
      [theme.breakpoints.down("sm")]: {
        maxWidth: "100%",
        flexBasis: "100%",
      },
    },
  },
  [theme.breakpoints.down("md")]: {
    ".Switches .react-tabs__tab": {
      fontSize: "13px",
      margin: "0 7px 10px 0",
      width: "calc(33.333% - (14px / 3))",
      minWidth: "128px",
      maxWidth: "100%",
      "&:nth-child(3n)": {
        marginRight: "0",
      },
    },
    ".Switches .react-tabs__tab span svg": { marginRight: "3px" },
  },
  [theme.breakpoints.down("md")]: {
    ".Switches .react-tabs__tab": {
      width: "100%",
    },
    ".Switches": {
      padding: "0 0 0 0",
      margin: "-8px 0 0 0",
    },
    ".Switches .react-tabs__tab-list": {
      overflow: "auto",
      minWidth: "250px",
      padding: "10px 0 0 0",
    },
    ".Switches .react-tabs__tab-list::-webkit-scrollbar": {
      width: "0",
      height: "0",
    },
    ".Switches .react-tabs__tab span": {
      padding: "6px 10px 6px 10px",
    },
  },
}));

const WrapDevicesBottom = styled(Box)(({ theme }) => ({
  display: "flex",
  justifyContent: "space-between",
  flexWrap: "wrap",
  gap: "20px",
  marginTop: "20px",
  [theme.breakpoints.down("md")]: {
    flexWrap: "wrap",
  },
}));

const HumidityBottom = styled(Box)(({ theme }) => ({
  display: "flex",
  justifyContent: "space-between",
  [theme.breakpoints.down("sm")]: {
    flexDirection: "column",
    alignItems: "center",
  },
}));

const HumidityBottomTemperature = styled(Box)(({ theme }) => ({
  fontSize: "20px",
  lineHeight: "23px",
  color: Colors.dimGray,
  padding: "23px 0 0 0",
  display: "flex",
  fontWeight: "400",
  "& span": {
    color: Colors.silver,
    display: "inline-block",
    padding: "0 4px 0 4px",
  },
  [theme.breakpoints.down("lg")]: {
    fontSize: "15px",
    lineHeight: "17px",
  },
}));

const HumidityBottomTemperatureLine = styled(Box)({
  color: Colors.silver,
  display: "inline-block",
  padding: "0 4px 0 4px",
});

const HumidityBottomData = styled(Box)(({ theme }) => ({
  fontSize: "20px",
  lineHeight: "23px",
  color: Colors.dimGray,
  padding: "23px 0 0 0",
  fontWeight: "400",
  "& span": {
    color: Colors.silver,
    display: "inline-block",
    padding: "0 4px 0 4px",
  },
  [theme.breakpoints.down("lg")]: {
    fontSize: "15px",
    lineHeight: "17px",
  },
}));

const CreatedBy = styled(Box)(({ theme }) => ({
  textAlign: "center",
  color: Colors.gray,
  fontFamily: "sans-serif",
  display: "none",
  padding: "20px 0 0 0",
  "& a": {
    display: "block",
    textDecoration: "none",
    color: Colors.gray,
  },
  "& span": {
    display: "block",
    whiteSpace: "nowrap",
  },
  [theme.breakpoints.down("lg")]: {
    display: "block",
    "& span": {
      fontSize: "12px",
    },
  },
}));

export default Switches;
