const tickVelocity = new Map<string, number>([
    ["day", 2*60*60],
    ["week", 8*60*60],
    ["month", 48*60*60],
    ["year", 365 * 24 * 60 * 60 / 12]
]);

const getTicksOfInterval = (
    startDate: number | undefined,
    endDate: number | undefined,
    interval: ("day" | "week" | "month" | "year") | number
) => {
    let ticks = [];
    if (startDate && endDate) {
        const velocity = typeof interval === "string" ? tickVelocity.get(interval) as number : interval as number;
        do {
            ticks.push(startDate);
            startDate += velocity;
        } while ( startDate <= endDate );
    }
    return ticks;
};

export default getTicksOfInterval;
