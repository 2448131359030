import React from "react";
import dayjs from "dayjs";
import { ChartItem } from "src/types/customTooltipType";
import { Typography } from "@mui/material";
import {
  TOOLTIP_NAME_HUMIDITY,
  EDUCATION_TO_TIME,
  TWO_DIGITS_HOUR_AND_TWO_DIGITS_MINUTE,
} from "src/system/constants";

const TOOLTIP_DATE_FORMAT = "D MMM";

const CustomTooltip: React.FC<ChartItem> = ({
  payload,
  isYearMode,
  renderSingleValue,
  classNameTooltipFirst,
  classNameTooltipSecond,
  paramTooltipFirst,
  paramTooltipSecond,
  paramNameTooltipFirst,
  paramNameTooltipSecond,
}) => {
  if (!payload) {
    return null;
  }

  const currentPayload = payload?.[0]?.payload;

  const paramTooltipDataFirst =
    currentPayload &&
    currentPayload[paramTooltipFirst || TOOLTIP_NAME_HUMIDITY]?.toFixed();

  const paramTooltipDataSecond =
    currentPayload &&
    currentPayload[paramTooltipSecond || TOOLTIP_NAME_HUMIDITY]?.toFixed();

  const timeTooltip =
    currentPayload &&
    dayjs(currentPayload.timestamp * EDUCATION_TO_TIME).format(
      isYearMode ? TOOLTIP_DATE_FORMAT : TWO_DIGITS_HOUR_AND_TWO_DIGITS_MINUTE
    );

  return (
    <Typography data-testid="CustomTooltip" className="custom-tooltip">
      <Typography className="time-tooltip">{timeTooltip}</Typography>
      {renderSingleValue ? (
        <Typography className={`param param-1 ${classNameTooltipFirst}`}>
          {paramTooltipDataFirst} {paramNameTooltipFirst}
        </Typography>
      ) : (
        <>
          <Typography className={`param param-1 ${classNameTooltipFirst}`}>
            {paramTooltipDataFirst} {paramNameTooltipFirst}
          </Typography>
          <Typography className={`param param-2 ${classNameTooltipSecond}`}>
            {paramTooltipDataSecond} {paramNameTooltipSecond}
          </Typography>
        </>
      )}
    </Typography>
  );
};

export default CustomTooltip;
